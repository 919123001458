import { Col, Table } from "react-bootstrap";
import { COLOR_DARK_BLUE, COLOR_WHITE } from "./ChartConstants";
import { translateClinicalEventKey } from "./MiscFns";
import { format } from "date-fns";

const IGNORED_EVENTS = new Set([
  "reg_mode-1",
  "displayed_icp_update",
  "drip",
  "displayed_orientation_update",
  "drainage_start",
  "drainage_end",
  "alarm_assert",
  "alarm_deassert",
  "alarm_autoclamp_request",
  "alarm_autoclamp_release",
  "settings_index_change",
]);

//   key === "5" ||
//   key === "3" ||
//   key === "4" ||
//   key === "16" ||
//   key === "17" ||
//   key === "1_255"

export function EventList(props) {
  const events = props.eventData;

  const eventsToDisplay = [];

  if (events !== undefined) {
    for (let key in events) {
      if (IGNORED_EVENTS.has(key.toLowerCase())) {
        continue;
      }

      const subEvents = events[key];
      const timestampData = subEvents.timestamps;
      for (let i = 0; i < timestampData.length; i++) {
        eventsToDisplay.push({
          timestamp: timestampData[i],
          eventKey: key,
        });
      }
    }
  }

  eventsToDisplay.sort((a, b) => -compare(a.timestamp, b.timestamp));

  for (let i = 0; i < eventsToDisplay.length; i++) {
    eventsToDisplay[i].rowNumber = i + 1;
  }

  return (
    <Col
      className="ms-2 p-2"
      style={{
        backgroundColor: COLOR_WHITE,
        borderStyle: "solid",
        borderColor: COLOR_DARK_BLUE,
        borderRadius: 12,
        borderWidth: 6,
      }}
    >
      <Table responsive className="table-hover">
        <thead>
          <tr>
            <th>Event</th>
            <th>Date/Time</th>
            <th>Flag Type</th>
            <th>Notes</th>
          </tr>
        </thead>
        <tbody>
          {eventsToDisplay.map((event) => (
            <EventEntry key={"event_" + event.rowNumber} event={event} />
          ))}
        </tbody>
      </Table>
    </Col>
  );
}

const compare = (a, b) => {
  if (a > b) return -1;
  if (a < b) return 1;
  return 0;
};

export function EventEntry(props) {
  const event = props.event;

  const eventDate = new Date(event.timestamp);
  const formattedDate = format(eventDate, "LLL do HH:mm:ss");

  return (
    <tr>
      <td>{event.rowNumber}</td>
      <td>{formattedDate}</td>
      <td>{translateClinicalEventKey(event.eventKey)}</td>
      <td></td>
    </tr>
  );
}
