import React, { useEffect, useState } from "react";
import { downloadData } from "./DataDownloader";
import { useOutletContext, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Col, Container, NavDropdown, Row } from "react-bootstrap";
import { titleFromMetaData } from "./SessionDataHelpers";
import {
  CHART_CONFIG,
  COLOR_CHART_BACKGROUND,
  COLOR_LIGHT_BLUE,
  COLOR_PRESSURE,
  COLOR_VOLUME,
  COLOR_WHITE,
  MARKER_SIZE_CLINICAL_ANNOTATIONS,
  MARKER_SIZE_DRIPS,
  PLOTLY_DEFAULT_LAYOUT,
  TABLEAU_LIGHT_GREEN,
  TABLEAU_RED,
  WHITE_AXIS,
  WHITE_GRID,
} from "./ChartConstants";
import { BrainSpaceSpinner } from "./BrainSpaceSpinner";
import {
  binarySearch,
  ensureDateIsMillis,
  MiscFns,
  parseDateParam,
} from "./MiscFns";
import Nav from "react-bootstrap/Nav";
import EasyEdit, { Types } from "react-easy-edit";
import { UnitOfMeasurementExpert } from "./UnitOfMeasurementExpert";
import { SessionMetaDataBlock } from "./SessionMetaDataBlock";
import Form from "react-bootstrap/Form";
import { EventList } from "./EventList";
import { createEventSeries, findPressure } from "./ChartFns";

const Plot = React.lazy(() => import("react-plotly.js"));

const VOLUME_PER_DRIP_IN_ML = 0.05;

export function SessionWaveform(props) {
  const [sessionData, setSessionData] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [sessionTitle, setSessionTitle] = useState(null);
  // eslint-disable-next-line  no-unused-vars
  const [navbarExtras, setNavbarExtras] = useOutletContext();
  const [showIndividualPressures, setShowIndividualPressures] = useState(false);

  const [showMovementValues, setShowMovementValues] = useState(false);
  const [showValuesOnHover, setShowValuesOnHover] = useState(false);
  const [showEvents, setShowEvents] = useState(false);

  const { sessionId, cartridgeDbId, timestampCenterParamString } = useParams();
  const timestampCenter = parseDateParam(timestampCenterParamString);

  let initialXRange = null;
  if (timestampCenter != null) {
    const xLeft = (timestampCenter - 30) * 1000;
    const xRight = (timestampCenter + 30) * 1000;
    initialXRange = [xLeft, xRight];
  }

  const [xRange, setXRange] = useState(initialXRange);
  const [yRange, setYRange] = useState(null);

  useEffect(() => {
    let url = null;
    if (sessionId !== undefined) {
      url = "/api/fetch_session_waveform/" + sessionId + "/" + timestampCenter;
    } else if (cartridgeDbId !== undefined) {
      url =
        "/api/fetch_cartridge_waveform/" +
        cartridgeDbId +
        "/" +
        timestampCenter;
    } else {
      return;
    }
    setSessionData(null);
    console.log("URL: " + url);
    downloadData(url, onNewData, setErrorMessage);
    // eslint-disable-next-line
  }, [sessionId, timestampCenter]);

  function onNewData(data) {
    const pressureConversionFactor =
      UnitOfMeasurementExpert.getPressureConversionFactor();
    data.samples.pressure1_values_converted = data.samples.pressure1_values.map(
      (x) => x * pressureConversionFactor,
    );
    data.samples.pressure2_values_converted = data.samples.pressure2_values.map(
      (x) => x * pressureConversionFactor,
    );
    data.samples.average_pressures_converted =
      data.samples.average_pressures.map((x) => x * pressureConversionFactor);

    setSessionData(data);

    if (data && data.meta_data && data.meta_data.cartridge_sn) {
      setSessionTitle(data.meta_data.cartridge_sn);
    } else {
      setSessionTitle("No title");
    }

    // Scan data and get min/max
    const pressures = data.samples.average_pressures_converted;
    if (0 < pressures.length) {
      let min = data.samples.average_pressures_converted[0];
      let max = data.samples.average_pressures_converted[0];
      for (let i = 0; i < pressures.length; i++) {
        const value = pressures[i];
        if (value < min) {
          min = value;
        }
        if (max < value) {
          max = value;
        }
      }
      const adjustment = 2.5;
      min -= adjustment;
      max += adjustment;
      setYRange([min, max]);
    }
  }

  useEffect(() => {
    let zoomedOutUrl = null;

    if (sessionId !== undefined) {
      zoomedOutUrl =
        "/session_historical_averages/" + sessionId + "/60/" + timestampCenter;
    } else if (cartridgeDbId !== undefined) {
      zoomedOutUrl =
        "/cartridge_historical_averages/" +
        cartridgeDbId +
        "/60/" +
        timestampCenter;
    } else {
      zoomedOutUrl = null;
    }

    function showP1P2() {
      setShowIndividualPressures(!showIndividualPressures);
    }

    let toggleBothPressureLabel;
    if (showIndividualPressures) {
      toggleBothPressureLabel = <>Hide&nbsp;Both&nbsp;Pressures</>;
    } else {
      toggleBothPressureLabel = <>Show&nbsp;Both&nbsp;Pressures</>;
    }

    const navLink = (
      <>
        <NavDropdown title="Chart Options" id="basic-nav-dropdown">
          <Nav.Link className="ms-2" onClick={showP1P2} href="#">
            {toggleBothPressureLabel}
          </Nav.Link>
        </NavDropdown>
        <Nav.Link className="ms-2" href={zoomedOutUrl}>
          Zoom Out
        </Nav.Link>
      </>
    );

    function handleTextUpdate(e) {
      setSessionTitle(e);

      const url = "/api/update_title";

      const data = {
        sessionId: sessionId,
        newTitle: e,
      };

      fetch(url, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
    }

    let title = "";
    if (sessionTitle) {
      title = (
        <EasyEdit
          attributes={{ display: "inline-block", name: "awesome-input", id: 1 }}
          type={Types.TEXT}
          value={sessionTitle}
          hideSaveButton={true}
          hideCancelButton={true}
          onSave={handleTextUpdate}
        />
      );
    }

    if (sessionData == null) {
      setNavbarExtras({
        navLink: null,
        title: null,
      });
    } else {
      setNavbarExtras({
        navLink: navLink,
        title: title,
      });
    }
  }, [
    sessionId,
    sessionData,
    timestampCenter,
    showIndividualPressures,
    sessionTitle,
    setNavbarExtras,
    cartridgeDbId,
  ]);

  if (errorMessage) {
    return <>{errorMessage}</>;
  }

  if (sessionData === null) {
    return <BrainSpaceSpinner />;
  }

  function onUpdate(figure, div) {
    try {
      if (figure.layout.xaxis.range !== undefined) {
        const newRange = figure.layout.xaxis.range;
        if (!MiscFns.arraysEqual(newRange, xRange)) {
          setXRange([...newRange]);
        }
      }
      if (figure.layout.yaxis.range !== undefined) {
        const newRange = figure.layout.yaxis.range;
        if (!MiscFns.arraysEqual(newRange, yRange)) {
          setYRange([...newRange]);
        }
      }
    } catch (e) {
      // Ignore
      console.log(e);
    }
  }

  const pressureUnitOfMeasurement =
    UnitOfMeasurementExpert.getPressureUnitString();

  const allSeries = [];

  const pressure_timestamps = sessionData.samples.timestamps;

  if (showIndividualPressures) {
    addPressureSeries(
      "Pressure 1",
      "Pressure 1",
      pressure_timestamps,
      sessionData.samples.pressure1_values_converted,
      COLOR_PRESSURE,
    );
    addPressureSeries(
      "Pressure 2",
      "Pressure 2",
      pressure_timestamps,
      sessionData.samples.pressure2_values_converted,
      TABLEAU_LIGHT_GREEN,
    );
  } else {
    addPressureSeries(
      "Pressure",
      "Pressure",
      pressure_timestamps,
      sessionData.samples.average_pressures_converted,
      COLOR_PRESSURE,
    );
  }

  if (showEvents) {
    addClinicalAnnotation("Drips", "4", MARKER_SIZE_DRIPS, TABLEAU_LIGHT_GREEN);

    addClinicalAnnotation(
      "Session Start",
      "M_0",
      MARKER_SIZE_CLINICAL_ANNOTATIONS,
      TABLEAU_RED,
    );
    addClinicalAnnotation(
      "Settings Change",
      "0",
      MARKER_SIZE_CLINICAL_ANNOTATIONS,
      TABLEAU_RED,
    );
    addClinicalAnnotation(
      "Position Change",
      "1_0",
      MARKER_SIZE_CLINICAL_ANNOTATIONS,
      TABLEAU_RED,
    );
    addClinicalAnnotation(
      "ICP Change",
      "1_1",
      MARKER_SIZE_CLINICAL_ANNOTATIONS,
      TABLEAU_RED,
    );
    addClinicalAnnotation(
      "Waveform Manipulation",
      "1_2",
      MARKER_SIZE_CLINICAL_ANNOTATIONS,
      TABLEAU_RED,
    );
    addClinicalAnnotation(
      "Troubleshooting Start",
      "1_3",
      MARKER_SIZE_CLINICAL_ANNOTATIONS,
      TABLEAU_RED,
    );
    addClinicalAnnotation(
      "Troubleshooting Stop",
      "1_4",
      MARKER_SIZE_CLINICAL_ANNOTATIONS,
      TABLEAU_RED,
    );
    addClinicalAnnotation(
      "CA - Other",
      "1_5",
      MARKER_SIZE_CLINICAL_ANNOTATIONS,
      TABLEAU_RED,
    );
  }

  if (showMovementValues) {
    const series = {
      name: "Movement",
      type: "scattergl",
      mode: "lines",
      yaxis: "y2",
      x: sessionData.samples.timestamps,
      y: sessionData.samples.total_gs,
      line: {
        color: TABLEAU_RED,
      },
      showlegend: true,
      hovertemplate: "%{y:,.3f}g<extra></extra>",
    };

    if (!showValuesOnHover) {
      series["hoverinfo"] = "skip";
      series["hovertemplate"] = null;
    }

    allSeries.push(series);
  }

  function addPressureSeries(name, shortName, xValues, yValues, color) {
    const series = {
      name: name,
      type: "scattergl",
      yaxis: "y1",
      mode: "lines",
      x: xValues,
      y: yValues,
      line: {
        color: color,
      },
      showlegend: true,
      hoverinfo: "text+x",
      hovertemplate:
        shortName +
        ": %{y:,.2f} " +
        pressureUnitOfMeasurement +
        "<extra></extra>",
    };
    if (!showValuesOnHover) {
      series["hoverinfo"] = "skip";
      series["hovertemplate"] = null;
    }
    allSeries.push(series);
  }

  function addClinicalAnnotation(label, key, marker_size, color) {
    if (!(key in sessionData.events)) {
      return;
    }

    const eventData = sessionData.events[key];

    eventData.pressures = [];

    for (let i = 0; i < eventData.timestamps.length; i++) {
      eventData.pressures.push(
        findPressure(
          eventData.timestamps[i],
          pressure_timestamps,
          sessionData.samples.average_pressures_converted,
        ),
      );
    }

    allSeries.push(
      createEventSeries(eventData, label, marker_size, color, "y1"),
    );
  }

  const layout = {
    ...PLOTLY_DEFAULT_LAYOUT,
    showlegend: false,
    xaxis: {
      ...WHITE_AXIS,
      ...WHITE_GRID,
      automargin: true,
      type: "date",
      tickangle: 0,
      nticks: 7,
      tickformatstops: [
        {
          dtickrange: [null, 999],
          value: "%H:%M:%S.%L",
        },
        {
          dtickrange: [1000, 59999],
          value: "%H:%M:%S",
        },
        {
          dtickrange: [60000, null],
          value: "%H:%M",
        },
      ],
    },
    yaxis: {
      automargin: true,
      ...WHITE_AXIS,
      ...WHITE_GRID,
      title: {
        text: "Pressure [" + pressureUnitOfMeasurement + "]",
        standoff: 20,
      },
    },
    hovermode: "x",
  };

  if (showMovementValues) {
    layout["yaxis2"] = {
      automargin: true,
      ...WHITE_AXIS,
      ...WHITE_GRID,
      title: {
        text: "g",
        // standoff: 150,
      },
      // overlaying: "y",
      // side: 'right',
      domain: [0, 0.3],
    };
    layout.yaxis["domain"] = [0.3, 1.0];
    layout["grid"] = {
      rows: 2,
      columns: 1,
      shared_xaxes: true,
    };
  }

  const shapes = [];
  const annotations = [];

  try {
    console.log(sessionData.drainages);

    for (let i = 0; i < sessionData.drainages.length; i++) {
      const drainage = sessionData.drainages[i];

      shapes.push({
        type: "rect",
        xref: "x",
        yref: "paper",
        x0: drainage.start_time_ms,
        x1: drainage.end_time_ms,
        y0: 0,
        y1: 1,
        fillcolor: COLOR_LIGHT_BLUE,
        // opacity: 0.3,
        line: {
          width: 0,
        },
        layer: "below",
      });

      const centerX = (drainage.start_time_ms + drainage.end_time_ms) / 2;

      annotations.push({
        xref: "x",
        yref: "paper",
        x: centerX,
        y: 0.98,
        text:
          "<b>" +
          drainage.volume +
          " " +
          UnitOfMeasurementExpert.getVolumeUnitString() +
          "</b>",
        font: {
          color: COLOR_WHITE,
        },
        showarrow: false,
      });
    }
  } catch (e) {
    // console.log('While charting drainage start/stop events');
    // console.log(e);
  }

  layout["shapes"] = shapes;
  layout["annotations"] = annotations;

  if (xRange !== null) {
    console.log("Setting layouts X range to " + xRange);
    layout.xaxis.range = [...xRange];
  }

  if (yRange !== null) {
    console.log("Setting layouts Y range to " + yRange);
    layout.yaxis.range = [...yRange];
  }

  const documentTitle = titleFromMetaData(sessionData.meta_data);

  const plotStyle = {
    width: "100%",
    height: "450px",
  };

  function computeAveragePressure() {
    const startDate = ensureDateIsMillis(xRange[0]);
    const endDate = ensureDateIsMillis(xRange[1]);
    let iStart = binarySearch(pressure_timestamps, startDate);
    let iEnd = binarySearch(pressure_timestamps, endDate);
    if (iStart < 0) {
      iStart = ~iStart;
    }
    if (iEnd < 0) {
      iEnd = ~iEnd - 1;
    }

    let count = 0;
    let sumPressure = 0;

    for (let i = iStart; i <= iEnd; i++) {
      count++;
      sumPressure += sessionData.samples.average_pressures_converted[i];
    }

    return sumPressure / count;
  }

  function computeVolumeDrainedInMl() {
    const startDate = ensureDateIsMillis(xRange[0]);
    const endDate = ensureDateIsMillis(xRange[1]);

    const eventKey = "4";

    if (!(eventKey in sessionData.events)) {
      return 0;
    }

    const eventTimestamps = sessionData.events[eventKey].timestamps;

    let iStart = binarySearch(eventTimestamps, startDate);
    let iEnd = binarySearch(eventTimestamps, endDate);
    if (iStart < 0) {
      iStart = ~iStart;
    }
    if (iEnd < 0) {
      iEnd = ~iEnd - 1;
    }
    return (iEnd - iStart + 1) * VOLUME_PER_DRIP_IN_ML;
  }

  const averagePressure = computeAveragePressure();
  const volumeDrainedInMl = computeVolumeDrainedInMl() * 1.0;

  return (
    <Container className="mt-0 mb-5 px-4">
      <Helmet>
        <title>{documentTitle}</title>
      </Helmet>
      <Row>
        <Col></Col>
      </Row>

      <Row
        style={{
          backgroundColor: COLOR_CHART_BACKGROUND,
          borderStyle: "solid",
          borderColor: COLOR_LIGHT_BLUE,
          borderRadius: 12,
          borderWidth: 6,
        }}
      >
        <Col className="pt-3 pb-3 ps-3 pe-0 col-10">
          <Plot
            className="m-0"
            data={allSeries}
            layout={layout}
            style={plotStyle}
            onUpdate={onUpdate}
            config={CHART_CONFIG}
          />
        </Col>
        <Col className="m-0 pl-0 pr-10 col-2" style={{ paddingLeft: 0 }}>
          <Container
            className="m-0 p-0 align-middle"
            style={{ color: "white", fontWeight: "bold" }}
          >
            <Row>
              <Col
                className="text-center mt-5 mb-2 ms-0"
                style={{ color: COLOR_PRESSURE, fontWeight: "bold" }}
              >
                Average Pressure: {averagePressure.toFixed(2)}{" "}
                {pressureUnitOfMeasurement}
              </Col>
            </Row>
            <Row>
              <Col
                className="text-center mt-3 mb-2 ms-0"
                style={{ color: COLOR_VOLUME, fontWeight: "bold" }}
              >
                Total Volume: {volumeDrainedInMl.toFixed(2)} ml
              </Col>
            </Row>
            <Row className="mt-5 mb-3">
              <Col>
                <small>Targets</small>
              </Col>
              <Col className="text-end align-self-center">
                <Form.Check type="switch" id="show-targets" />
              </Col>
            </Row>
            <Row className="mt-3 mb-3">
              <Col>
                <small>Movement</small>
              </Col>
              <Col className="text-end align-self-center">
                <Form.Check
                  onChange={onSwitchMovement}
                  checked={showMovementValues}
                  type="switch"
                  id="show-movement"
                />
              </Col>
            </Row>
            <Row className="mt-3 mb-3">
              <Col>
                <small>Events</small>
              </Col>
              <Col className="text-end align-self-center">
                <Form.Check
                  onChange={onSwitchShowEvents}
                  checked={showEvents}
                  type="switch"
                  id="show-events"
                />
              </Col>
            </Row>
            <Row className="mt-3 mb-3">
              <Col>
                <small>Values on Hover</small>
              </Col>
              <Col className="text-end align-self-center">
                <Form.Check
                  onChange={onSwitchShowValuesOnHover}
                  checked={showValuesOnHover}
                  type="switch"
                  id="show-values"
                />
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>

      <Row className="mt-3 mb-5">
        <SessionMetaDataBlock metaData={sessionData.meta_data} />
        <EventList eventData={sessionData.events} />
      </Row>
    </Container>
  );

  function onSwitchMovement(e) {
    setShowMovementValues(e.target.checked);
  }

  function onSwitchShowValuesOnHover(e) {
    setShowValuesOnHover(e.target.checked);
  }

  function onSwitchShowEvents(e) {
    setShowEvents(e.target.checked);
  }
}
