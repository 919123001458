import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";

export function VerifyCode(props) {
  const [verificationCode, setVerificationCode] = useState("");
  const [error, setError] = useState(null);
  const [responseCompleted, setResponseCompleted] = useState(false);

  if (error) {
    return <>{error}</>;
  }

  if (responseCompleted) {
    window.location.href = "/";
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log("Sending verification");

    const clientSecret = localStorage.getItem("clientSecret");

    const data = {
      client_secret: clientSecret,
      verification_code: verificationCode,
    };

    const url = "/api/verify_access";

    fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        setResponseCompleted(true);
      })
      .catch((error) => {
        setError(error);
      });
  };

  const handleChange = (event) => {
    setVerificationCode(event.target.value);
  };

  const upperCaseStyle = {
    "text-transform": "uppercase",
  };

  return (
    <div className="d-flex justify-content-center align-items-center vh-100">
      <Form onSubmit={handleSubmit} className="p-5 border rounded">
        <h3 className="mb-3">Verification Code</h3>
        <Form.Group controlId="formVerificationCode">
          <Form.Control
            type="text"
            style={upperCaseStyle}
            placeholder="Enter verification code"
            value={verificationCode}
            onChange={handleChange}
            className="mb-3"
          />
        </Form.Group>
        <Button variant="primary" type="submit" className="w-100">
          Verify
        </Button>
      </Form>
    </div>
  );
}
