import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Modal,
  NavDropdown,
  Row,
} from "react-bootstrap";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { downloadData } from "./DataDownloader";
import { Helmet } from "react-helmet";
import { BrainSpaceSpinner } from "./BrainSpaceSpinner";
import { titleFromMetaData } from "./SessionDataHelpers";
import {
  COLOR_CHART_BACKGROUND,
  COLOR_LIGHT_BLUE,
  COLOR_PRESSURE,
  COLOR_VOLUME,
  COLOR_WHITE,
  MARKER_SIZE_CLINICAL_ANNOTATIONS,
  PLOTLY_DEFAULT_LAYOUT,
  TABLEAU_RED,
  WHITE_AXIS,
  WHITE_GRID,
} from "./ChartConstants";
import { MiscFns, parseDateParam } from "./MiscFns";
import Nav from "react-bootstrap/Nav";
import EasyEdit, { Types } from "react-easy-edit";
import { MiscFns as DashboardFns } from "./DashboardFns";
import { UnitOfMeasurementExpert } from "./UnitOfMeasurementExpert";
import { SessionMetaDataBlock } from "./SessionMetaDataBlock";
import { EventList } from "./EventList";
import Form from "react-bootstrap/Form";
import { createEventSeries, findPressure } from "./ChartFns";

const SECONDS_PER_MINUTE = 60;
const SECONDS_PER_HOUR = 60 * SECONDS_PER_MINUTE;
const SECONDS_PER_DAY = 24 * SECONDS_PER_HOUR;

const MILLIS_PER_MINUTE = 60 * 1000;
const MILLIS_PER_HOUR = 60 * MILLIS_PER_MINUTE;
const MILLIS_PER_DAY = 24 * MILLIS_PER_HOUR;

const Plot = React.lazy(() => import("react-plotly.js"));

function convertDate(d) {
  if (typeof d === "string") {
    d = Date.parse(d.replace(" ", "T"));
  }
  return d;
}

function getRange(data, minSpan) {
  if (data.length === 0) {
    return [0, minSpan];
  }

  let minValue = Math.min(...data);
  let maxValue = Math.max(...data);

  minValue -= 1;
  maxValue += 1;

  let span = maxValue - minValue;

  if (minSpan <= span) {
    return [minValue, maxValue];
  }

  let remainingSpan = minSpan - span;

  minValue = Math.max(0, minValue - remainingSpan);
  maxValue = minValue + minSpan;

  return [minValue, maxValue];
}

const binarySearchFn = require("binary-search");

function getIndexOfDate(date, data) {
  date = convertDate(date);
  let x = binarySearchFn(data, date, function (element, needle) {
    return element - needle;
  });
  if (x < 0) {
    x *= -1;
    x--;
  }
  return x;
}

export function SessionSummary(props) {
  DashboardFns.moveSecretToCookie();

  const { sessionId, cartridgeDbId, bucketSizeParam, timestampCenterParam } =
    useParams();
  const bucketSize = parseInt(bucketSizeParam);
  const timestampCenter = parseDateParam(timestampCenterParam);

  const [sessionData, setSessionData] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [navbarExtras, setNavbarExtras] = useOutletContext();
  const [sessionTitle, setSessionTitle] = useState(null);

  const [xRange, setXRange] = useState(null);
  const [yRange, setYRange] = useState(null);
  const [y2Range, setY2Range] = useState(null);

  const [showMovementData, setShowMovementData] = useState(false);
  const [showEvents, setShowEvents] = useState(false);

  const [displayDeleteConfirmation, setDisplayDeleteConfirmation] =
    useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    let url = null;
    if (sessionId !== undefined) {
      url =
        "/api/fetch_session_historical_averages/" +
        sessionId +
        "/" +
        bucketSize;
    } else if (cartridgeDbId !== undefined) {
      url =
        "/api/fetch_cartridge_historical_averages/" +
        cartridgeDbId +
        "/" +
        bucketSize;
    }
    if (url !== null) {
      downloadData(url, onNewData, setErrorMessage);
    }
    // eslint-disable-next-line
  }, [sessionId, bucketSize]);

  function onNewData(data) {
    const pressureConversionFactor =
      UnitOfMeasurementExpert.getPressureConversionFactor();
    data.average_pressures_converted = data.average_pressures.map(
      (x) => x * pressureConversionFactor,
    );

    console.log(data);

    setSessionData(data);

    if (data && data.meta_data && data.meta_data.cartridge_sn) {
      setSessionTitle(data.meta_data.cartridge_sn);
    } else {
      setSessionTitle("No title");
    }

    let chartWidthInMillis = 60;
    if (bucketSize === SECONDS_PER_MINUTE) {
      chartWidthInMillis = MILLIS_PER_HOUR;
    } else if (bucketSize === SECONDS_PER_HOUR) {
      chartWidthInMillis = MILLIS_PER_DAY;
    } else if (bucketSize === SECONDS_PER_DAY) {
      chartWidthInMillis = 30 * MILLIS_PER_DAY;
    }

    let leftX;

    if (timestampCenter == null) {
      const timestamps = data.timestamps;
      const centerTs = (timestamps[0] + timestamps[timestamps.length - 1]) / 2;
      leftX = centerTs - chartWidthInMillis / 2;
    } else {
      leftX = timestampCenter * 1000 - chartWidthInMillis / 2;
    }

    const rightX = leftX + chartWidthInMillis;
    const bucketSizeMillis = 2 * bucketSize * 1000;
    setXRange([leftX - bucketSizeMillis, rightX + bucketSizeMillis]);

    let maxVolume = Math.max(...data.volumes);
    maxVolume = Math.floor((maxVolume + 7.5) / 5.0) * 5.0;
    setYRange([0, maxVolume]);
    setY2Range(getRange(data.average_pressures_converted, 5.0));
  }

  useEffect(() => {
    let zoomedOutNavLink = null;

    if (bucketSize <= 3600) {
      let newBucketSize;
      if (bucketSize === 60) {
        newBucketSize = 3600;
      } else if (bucketSize === 3600) {
        newBucketSize = 24 * 3600;
      }
      let zoomedOutUrl = null;
      if (cartridgeDbId !== null) {
        zoomedOutUrl =
          "/cartridge_historical_averages/" +
          cartridgeDbId +
          "/" +
          newBucketSize +
          "/" +
          timestampCenter;
      } else if (sessionId !== null) {
        zoomedOutUrl =
          "/session_historical_averages/" +
          sessionId +
          "/" +
          newBucketSize +
          "/" +
          timestampCenter;
      }
      zoomedOutNavLink = (
        <>
          <Nav.Link className="ms-2" href={zoomedOutUrl}>
            Zoom Out
          </Nav.Link>
        </>
      );
    }

    let csvLink = null;

    try {
      if (xRange != null) {
        const csvUrl =
          "/api/download_csv/" +
          sessionId +
          "/" +
          xRange[0] / 1000 +
          "/" +
          xRange[1] / 1000;
        csvLink = <Nav.Link href={csvUrl}>Download</Nav.Link>;
      }
    } catch (e) {
      console.log(e);
    }

    let showMovementLabel = "Show Movement";
    if (showMovementData) {
      showMovementLabel = "* Show Movement";
    }

    function toggleMovement() {
      setShowMovementData(!showMovementData);
    }

    function showDeleteConfirmationModal() {
      setDisplayDeleteConfirmation(true);
    }

    const navLink = (
      <>
        <NavDropdown title="Options" id="uom-nav-dropdown">
          <Nav.Link href="#" onClick={toggleMovement}>
            {showMovementLabel}
          </Nav.Link>
          {csvLink}
          <Nav.Link href="#" onClick={showDeleteConfirmationModal}>
            Delete
          </Nav.Link>
        </NavDropdown>
        {zoomedOutNavLink}
      </>
    );

    function handleTextUpdate(e) {
      setSessionTitle(e);

      const url = "/api/update_title";

      const data = {
        sessionId: sessionId,
        newTitle: e,
      };

      fetch(url, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
    }

    let title = "";
    if (sessionTitle) {
      title = (
        <EasyEdit
          attributes={{ display: "inline-block", name: "awesome-input", id: 1 }}
          type={Types.TEXT}
          value={sessionTitle}
          hideSaveButton={true}
          hideCancelButton={true}
          onSave={handleTextUpdate}
        />
      );
    }

    setNavbarExtras({
      navLink: navLink,
      title: title,
    });
  }, [
    sessionId,
    bucketSize,
    sessionTitle,
    xRange,
    showMovementData,
    setNavbarExtras,
    timestampCenter,
    cartridgeDbId,
  ]);

  if (errorMessage) {
    return <>{errorMessage}</>;
  }

  if (sessionData == null) {
    return <BrainSpaceSpinner />;
  }

  function onUpdate(figure, div) {
    try {
      if (figure.layout.xaxis.range !== undefined) {
        const newRange = figure.layout.xaxis.range;
        if (!MiscFns.arraysEqual(newRange, xRange)) {
          setXRange([...newRange]);
        }
      }
      if (figure.layout.yaxis.range !== undefined) {
        const newRange = figure.layout.yaxis.range;
        if (!MiscFns.arraysEqual(newRange, yRange)) {
          setYRange([...newRange]);
        }
      }
      if (figure.layout.yaxis2.range !== undefined) {
        const newRange = figure.layout.yaxis2.range;
        if (!MiscFns.arraysEqual(newRange, y2Range)) {
          setY2Range([...newRange]);
        }
      }
    } catch (e) {
      // Ignore
      console.log(e);
    }
  }

  // Translate the range (dates) into indexes into arrays
  const [averagePressure, totalVolume] = computeStatistics();

  const documentTitle = titleFromMetaData(sessionData.meta_data);

  const pressureUnitOfMeasurement =
    UnitOfMeasurementExpert.getPressureUnitString();

  const allSeries = [];

  const showHoverMode = true;

  const pressureUnits = UnitOfMeasurementExpert.getPressureUnitString();
  const volumeUnits = UnitOfMeasurementExpert.getVolumeUnitString();

  let series = {
    name: "Average Pressure",
    type: "scatter",
    yaxis: "y2",
    x: sessionData.timestamps,
    y: sessionData.average_pressures_converted,
    line: {
      color: COLOR_PRESSURE,
      width: 4,
    },
    showlegend: true,
    hoverinfo: "x",
    hovertemplate: "%{y:,.2f} " + pressureUnits + "<extra></extra>",
    offsetgroup: 1,
  };
  if (!showHoverMode) {
    series["hoverinfo"] = "skip";
    series["hovertemplate"] = null;
  }
  allSeries.push(series);

  series = {
    name: "Volume",
    type: "bar",
    yaxis: "y1",
    x: sessionData.timestamps,
    y: sessionData.volumes,
    width: sessionData.timestamps.map((x) => bucketSize * 800),
    marker: {
      color: COLOR_VOLUME,
    },
    showlegend: true,
    hoverinfo: "x",
    hovertemplate: "%{y:,.2f} " + volumeUnits + "<extra></extra>",
    offsetgroup: 2,
  };
  if (!showHoverMode) {
    series["hoverinfo"] = "skip";
    series["hovertemplate"] = null;
  }
  allSeries.push(series);

  if (showMovementData) {
    const series = {
      name: "Movement",
      type: "scattergl",
      mode: "lines",
      yaxis: "y3",
      x: sessionData.timestamps,
      y: sessionData.max_total_gs,
      line: {
        color: TABLEAU_RED,
      },
      showlegend: true,
      hovertemplate: "%{y:,.3f}g<extra></extra>",
    };
    allSeries.push(series);
  }

  const layout = {
    ...PLOTLY_DEFAULT_LAYOUT,
    barmode: "group",
    showlegend: false,
    xaxis: {
      ...WHITE_AXIS,
      ...WHITE_GRID,
      automargin: true,
      type: "date",
      tickangle: 0,
      nticks: 7,
      tickformatstops: [
        {
          dtickrange: [null, 999],
          value: "%H:%M:%S.%L",
        },
        {
          dtickrange: [1000, 59999],
          value: "%H:%M:%S",
        },
        {
          dtickrange: [60000, 3600000],
          value: "%H:%M",
        },
        {
          dtickrange: [3600000, null],
          value: "%H:%M\n%b %e",
        },
      ],
    },
    yaxis2: {
      ...WHITE_AXIS,
      automargin: true,
      title: {
        text: "Pressure [" + pressureUnits + "]",
        standoff: 20,
      },
      zerolinecolor: COLOR_WHITE,
      gridcolor: "gray",
      overlaying: "y",
    },
    yaxis: {
      ...WHITE_AXIS,
      automargin: true,
      title: {
        text: "Volume [" + volumeUnits + "]",
        standoff: 20,
      },
      side: "right",
      showgrid: false,
    },
    hovermode: "x",
  };

  function addClinicalAnnotation(label, key) {
    if (!(key in sessionData.events)) {
      return;
    }

    const eventData = sessionData.events[key];

    eventData.pressures = [];

    for (let i = 0; i < eventData.timestamps.length; i++) {
      eventData.pressures.push(
        findPressure(
          eventData.timestamps[i],
          sessionData.timestamps,
          sessionData.average_pressures_converted,
        ),
      );
    }

    allSeries.push(
      createEventSeries(
        eventData,
        label,
        MARKER_SIZE_CLINICAL_ANNOTATIONS,
        TABLEAU_RED,
        "y2",
      ),
    );
  }

  if (showEvents) {
    addClinicalAnnotation("Session Start (Pressure)", "M_0");
    addClinicalAnnotation("Session Start (Volume - Top of Hour)", "M_1");
    addClinicalAnnotation("Session Start (Volume - Continuous)", "M_2");
    addClinicalAnnotation("Settings Change", "0");
    addClinicalAnnotation("Position Change", "1_0");
    addClinicalAnnotation("ICP Change", "1_1");
    addClinicalAnnotation("Waveform Manipulation", "1_2");
    addClinicalAnnotation("Troubleshooting Start", "1_3");
    addClinicalAnnotation("Troubleshooting Stop", "1_4");
    addClinicalAnnotation("CA - Other", "1_5");
  }

  if (showMovementData) {
    layout["yaxis3"] = {
      automargin: true,
      ...WHITE_AXIS,
      ...WHITE_GRID,
      title: {
        text: "g",
      },
      domain: [0, 0.2],
    };
    layout.yaxis["domain"] = [0.3, 1.0];
    layout.yaxis2["domain"] = [0.3, 1.0];
    layout["grid"] = {
      rows: 2,
      columns: 1,
      shared_xaxes: true,
    };
  } else {
    layout.xaxis.fixedrange = true;
    layout.xaxis.rangeslider = true;
  }

  if (xRange !== null) {
    console.log("Setting layouts X range to " + xRange);
    layout.xaxis.range = [...xRange];
  }

  if (yRange !== null) {
    console.log("Setting layouts Y range to " + yRange);
    layout.yaxis.range = [...yRange];
  }

  if (y2Range !== null) {
    if (layout.yaxis2 !== undefined) {
      layout.yaxis2.range = [...y2Range];
    }
  }

  const config = {
    displayModeBar: false,
    showTips: false,
  };

  const plotStyle = {
    width: "100%",
    height: "450px",
  };

  function onBarClick(data) {
    let clickTimestamp = convertDate(data.points[0].x);
    let nextBucketSize = 3600;
    const centerTs = clickTimestamp / 1000;
    let newCenterTs = centerTs;

    if (bucketSize === 60) {
      if (sessionId !== undefined) {
        navigate("/session_waveform/" + sessionId + "/" + (centerTs + 30));
      } else if (cartridgeDbId !== undefined) {
        navigate(
          "/cartridge_waveform/" + cartridgeDbId + "/" + (centerTs + 30),
        );
      }
      return;
    }

    if (bucketSize === 24 * 3600) {
      nextBucketSize = 3600;
      newCenterTs = centerTs + 12 * 3600;
    } else if (bucketSize === 3600) {
      nextBucketSize = 60;
      newCenterTs = centerTs + 1800;
    }

    let url = null;
    if (sessionId !== undefined) {
      url =
        "/session_historical_averages/" +
        sessionId +
        "/" +
        nextBucketSize +
        "/" +
        newCenterTs;
    } else if (cartridgeDbId !== undefined) {
      url =
        "/cartridge_historical_averages/" +
        cartridgeDbId +
        "/" +
        nextBucketSize +
        "/" +
        newCenterTs;
    }

    navigate(url);
  }

  return (
    <Container className="mt-0">
      <Helmet>
        <title>{documentTitle}</title>
      </Helmet>

      <Row
        style={{
          backgroundColor: COLOR_CHART_BACKGROUND,
          borderStyle: "solid",
          borderColor: COLOR_LIGHT_BLUE,
          borderRadius: 12,
          borderWidth: 6,
        }}
      >
        <Col className="pt-3 pb-3 ps-3 pe-3 col-10">
          <Plot
            data={allSeries}
            layout={layout}
            onUpdate={onUpdate}
            onClick={onBarClick}
            onHover={onHover}
            onUnhover={onUnhover}
            style={plotStyle}
            config={config}
          />
        </Col>
        <Col className="m-0 pl-0 pr-10 col-2" style={{ paddingLeft: 0 }}>
          <Container
            className="m-0 p-0 align-middle"
            style={{ color: "white", fontWeight: "bold" }}
          >
            <Row>
              <Col
                className="text-center mt-5 mb-2 ms-0"
                style={{ color: COLOR_PRESSURE, fontWeight: "bold" }}
              >
                Average Pressure: {averagePressure.toFixed(2)}{" "}
                {pressureUnitOfMeasurement}
              </Col>
            </Row>
            <Row>
              <Col
                className="text-center mt-3 mb-2 ms-0"
                style={{ color: COLOR_VOLUME, fontWeight: "bold" }}
              >
                Total Volume: {totalVolume.toFixed(2)} ml
              </Col>
            </Row>
            <Row className="mt-3 mb-3">
              <Col>
                <small>Events</small>
              </Col>
              <Col className="text-end align-self-center">
                <Form.Check
                  onChange={onSwitchShowEvents}
                  checked={showEvents}
                  type="switch"
                  id="show-events"
                />
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>

      <Row className="mt-3 mb-5">
        <SessionMetaDataBlock metaData={sessionData.meta_data} />
        <EventList eventData={sessionData.events} />
      </Row>

      <Modal
        show={displayDeleteConfirmation}
        onHide={hideDeleteConfirmationModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete Session</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="alert alert-danger">
            Are you sure would like to delete this session?
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="default" onClick={hideDeleteConfirmationModal}>
            Cancel
          </Button>
          <Button variant="danger" onClick={deleteSession}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );

  function onHover(e) {
    console.log("onHover: " + e);
    console.log(e.points);
    const dragLayer = document.getElementsByClassName("nsewdrag")[0];
    dragLayer.style.cursor = "pointer";
  }

  function onUnhover(e) {
    console.log("onUnhover: " + e);
    const dragLayer = document.getElementsByClassName("nsewdrag")[0];
    dragLayer.style.cursor = "";
  }

  function deleteSession() {
    const url = "/api/archive_session";

    const data = {
      sessionId: sessionId,
    };

    fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });

    setDisplayDeleteConfirmation(false);
    navigate("/");
  }

  function hideDeleteConfirmationModal() {
    setDisplayDeleteConfirmation(false);
  }

  function computeStatistics() {
    const xStartIndex = getIndexOfDate(xRange[0], sessionData.timestamps);
    const xEndIndex = getIndexOfDate(xRange[1], sessionData.timestamps);
    let sumPressure = 0;
    let countPressure = 0;
    let sumVolume = 0;
    for (let i = xStartIndex; i < xEndIndex; i++) {
      sumPressure += sessionData.average_pressures_converted[i];
      countPressure++;
      sumVolume += sessionData.volumes[i];
    }
    const averagePressure = sumPressure / countPressure;
    return [averagePressure, sumVolume];
  }

  function onSwitchShowEvents(e) {
    setShowEvents(e.target.checked);
  }
}
