import React, { useEffect, useState } from "react";
import { downloadData } from "./DataDownloader";
import { ErrorMessage } from "./ErrorMessage";
import { BrainSpaceSpinner } from "./BrainSpaceSpinner";
import { Container, NavDropdown, Table } from "react-bootstrap";
import { MiscFns } from "./MiscFns";
import { useNavigate, useOutletContext } from "react-router-dom";
import Nav from "react-bootstrap/Nav";

function CartridgeListEntry(props) {
  const navigate = useNavigate();

  const cartridgeData = props.cartridgeData;
  const bucketSize = 24 * 3600;

  const averagesLink =
    "/cartridge_historical_averages/" +
    cartridgeData.cartridge_db_id +
    "/" +
    bucketSize;

  function onRowClick() {
    navigate(averagesLink);
  }

  return (
    <tr onClick={() => onRowClick(cartridgeData)}>
      <td>{cartridgeData.cartridge_identifier}</td>
      <td>
        {new Intl.DateTimeFormat("en-US", {
          month: "2-digit",
          day: "2-digit",
          year: "numeric",
          hour12: true,
          hour: "2-digit",
          minute: "2-digit",
        }).format(cartridgeData.activation_time * 1000)}{" "}
        <span className="fw-lighter small">
          ({MiscFns.humanReadableAge(cartridgeData.activation_time)})
        </span>
      </td>
      <td>{cartridgeData.session_count}</td>
      <td>
        {MiscFns.humanReadableDuration(cartridgeData.total_length_seconds)}
      </td>
      <td>
        {new Intl.DateTimeFormat("en-US", {
          month: "2-digit",
          day: "2-digit",
          year: "numeric",
          hour12: true,
          hour: "2-digit",
          minute: "2-digit",
        }).format(cartridgeData.most_recent_upload_time * 1000)}{" "}
        <span className="fw-lighter small">
          ({MiscFns.humanReadableAge(cartridgeData.most_recent_upload_time)})
        </span>
      </td>
    </tr>
  );
}

export function CartridgeList() {
  const [data, setData] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [navbarExtras, setNavbarExtras] = useOutletContext();

  useEffect(() => {
    const url = "/api/fetch_cartridge_list";
    downloadData(url, setData, setErrorMessage);
  }, []);

  useEffect(() => {
    let navLink = (
      <>
        <NavDropdown title="Tools" id="tools-nav-dropdown">
          <Nav.Link href="/settings">Settings</Nav.Link>
        </NavDropdown>
        <Nav.Link href="/upload_files">Upload</Nav.Link>
      </>
    );
    const navbarExtras = {
      navLink: navLink,
    };
    setNavbarExtras(navbarExtras);
  }, [setNavbarExtras]);

  if (errorMessage != null) {
    return <ErrorMessage errorMessage={errorMessage} />;
  }

  if (data == null) {
    return <BrainSpaceSpinner />;
  }

  return (
    <div>
      <Container>
        <Table responsive className="table-hover">
          <thead>
            <tr>
              <th>Cartridge Name</th>
              <th>Activate Date</th>
              <th>Session Count</th>
              <th>Length</th>
              <th>Date Uploaded</th>
            </tr>
          </thead>
          <tbody>
            {data.map((cartridgeData) => (
              <CartridgeListEntry
                key={"CartridgeList_" + cartridgeData.cartridge_db_id}
                cartridgeData={cartridgeData}
              />
            ))}
          </tbody>
        </Table>
      </Container>
    </div>
  );
}
