import React, { useEffect, useState } from "react";
import { Container, NavDropdown, Table } from "react-bootstrap";
import { downloadData } from "./DataDownloader";
import { useNavigate, useOutletContext } from "react-router-dom";
import { ErrorMessage } from "./ErrorMessage";
import { BrainSpaceSpinner } from "./BrainSpaceSpinner";
import { MiscFns } from "./MiscFns";
import Nav from "react-bootstrap/Nav";

function SessionListEntry(props) {
  const navigate = useNavigate();

  const session = props.session;
  const sessionId = session.id;

  let bucketSize = 60;
  if (session.length_in_seconds < 2 * 3600) {
    bucketSize = 60;
  } else if (session.length_in_seconds < 2 * 24 * 3600) {
    bucketSize = 3600;
  } else {
    bucketSize = 24 * 3600;
  }
  const averagesLink =
    "/session_historical_averages/" + sessionId + "/" + bucketSize;

  function onRowClick() {
    navigate(averagesLink);
  }

  return (
    <tr onClick={() => onRowClick(session)}>
      <td>{session.short_title}</td>
      <td>{MiscFns.humanReadableDuration(session.length_in_seconds)}</td>
      <td>
        {new Intl.DateTimeFormat("en-US", {
          month: "2-digit",
          day: "2-digit",
          year: "numeric",
          hour12: true,
          hour: "2-digit",
          minute: "2-digit",
        }).format(session.start_time * 1000)}{" "}
        <span className="fw-lighter small">
          ({MiscFns.humanReadableAge(session.start_time)})
        </span>
      </td>
    </tr>
  );
}

export function SessionList() {
  const [sessionData, setSessionData] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [navbarExtras, setNavbarExtras] = useOutletContext();

  useEffect(() => {
    const url = "/api/fetch_session_list";
    downloadData(url, setSessionData, setErrorMessage);
  }, []);

  useEffect(() => {
    let navLink = (
      <>
        <NavDropdown title="Tools" id="tools-nav-dropdown">
          <Nav.Link href="/settings">Settings</Nav.Link>
        </NavDropdown>
        <Nav.Link href="/upload_files">Upload</Nav.Link>
      </>
    );
    const navbarExtras = {
      navLink: navLink,
    };
    setNavbarExtras(navbarExtras);
  }, [setNavbarExtras]);

  if (errorMessage != null) {
    return <ErrorMessage errorMessage={errorMessage} />;
  }

  if (sessionData == null) {
    return <BrainSpaceSpinner />;
  }

  return (
    <div>
      <Container>
        <Table responsive className="table-hover">
          <thead>
            <tr>
              <th>Session Name</th>
              <th>Length</th>
              <th>Date</th>
            </tr>
          </thead>
          <tbody>
            {sessionData.map((session) => (
              <SessionListEntry
                key={"SessionList_" + session.id}
                session={session}
              />
            ))}
          </tbody>
        </Table>
      </Container>
    </div>
  );
}
